import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';

import './index.css';
import App from './App';
import config from './config.json';


Amplify.configure(config);

const AuthedApp = (props) => (props.authState === 'signedIn')
  ? <App orgId={props.authData.attributes['custom:org_guid']} />
  : null;

const Authenticator = withAuthenticator(AuthedApp);

ReactDOM.render(
  <React.StrictMode>
      <Authenticator />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
