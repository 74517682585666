import API from '@aws-amplify/api';
import _ from 'lodash';


function toCategoryProductCode(input) {
    return {
        categoryProductCode: _.pick(input, ["categoryCode", "productCode"]),
        ...(_.omit(input, ["categoryCode", "productCode"]))
    }
}

export function collectProducts(brandPreferences) {
    const categoryProducts = brandPreferences.map(toCategoryProductCode);
    const categoryProductCodes = _.uniqWith(categoryProducts.map(cp => cp.categoryProductCode), _.isEqual);
    const products = categoryProductCodes.map(cpc => {
        const prefs = categoryProducts.filter(cp =>
            _.isEqual(cp.categoryProductCode, cpc)
        )
        return prefs.reduce((product, pref) => {
            const newShape = _.pick(pref, [
                "preferenceSelection",
                "preferenceSelectionCode",
                "shape",
                "shapeCode"
            ]);

            product.shapes = product.shapes.concat(newShape);
            return product;
        }, {...(_.pick(prefs[0], [
                "categoryProductCode",
                "category",
                "product",
                "preference",
                "preferenceCode"
            ])),
            shapes: []
        });
    });

    const prefCodes = _.uniq(products.map(p => p.preferenceCode));
    const matchedPrefs = prefCodes.reduce((obj, pc) => {
        const matchedProductsSels = products.reduce((arr, p) => {
            if (p.preferenceCode === pc)
                return arr.concat([p.shapes.map(shape => shape.preferenceSelectionCode)]);
            else
                return arr;
        }, []);
        const sharedSels = _.intersection(...matchedProductsSels);
        return {...obj, [pc]: sharedSels}
    }, {});
    
    const matchedProducts = products.map(product => {
        return {
            ...product,
            shapes: product.shapes.filter(shape =>
                matchedPrefs[product.preferenceCode].includes(shape.preferenceSelectionCode)
            )
        }
    })
    return matchedProducts;
}

export async function getGppLogicEngineResults(orgId, user_handle, gppRequest, forceExecution=false) {
    const path = `/users/${user_handle}/data/gpp-logic-engine`;
    return await API.post('org-service-api', path, {
        headers: {
            'org-id': orgId,
            'force-execution': forceExecution.toString()
        },
        body: gppRequest
    })
}

export function collectCustomerRecommendations(gppResults) {
    if (gppResults.output) {
        return gppResults.output.map(r => _.pick(toCategoryProductCode(r), [
            "categoryProductCode",
            "shape",
            "shapeCode",
            "size",
            "garmentMeasurementString"
        ]));
    } else {
        return [];
    }
}
